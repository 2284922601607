<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <h1 class="blue-grey--text nunito lighten-3"><b>Logs</b></h1>
      <v-divider class="mb-2"></v-divider>
      <div>
        <v-row v-for="(item, i) in logs" :key="i">
          <v-col cols="1">
            <v-avatar
              size="54"
              color="light-blue darken-1 white--text"
              class="my-2"
            >
              <img
                :src="require(`../../../assets/users/users-${item.icon}.svg`)"
                :alt="item.name.charAt(0)"
                v-if="item.icon"
              />
              <span v-else>{{ item.name.charAt(0) }}</span>
            </v-avatar>
          </v-col>
          <v-col>
            <div class="flex flex-column nunito">
              <span
                ><b class="green--text">{{ item.name }}</b>
                {{ item.activity }}</span
              >
              <span>
                <v-icon>mdi-calendar-range</v-icon>
                <span class="pl-2">{{ item.date }}</span>
              </span>
              <span>
                <v-icon>mdi-clock-time-eight-outline</v-icon>
                <span class="pl-2">{{ item.time }}</span>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "AuditTrail",

  data() {
    return {
      logs: [
        {
          name: "Harry Potter",
          activity: "disturbs the Chambers of Secret",
          date: "20/4/2021",
          time: "2 days ago",
          icon: 2,
        },
        {
          name: "Frozen Yogurt",
          activity: "change Bungkusit API ID",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "renew Premuim Subscription",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "added Product to Inventory",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "added new product Strawberry",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Ron",
          activity: "lose in chess",
          date: "19/4/2021",
          time: "3 days ago",
          icon: 4,
        },
      ],
      // {
      //   name: "Melissa",
      //   store: "Miko cafe",
      // },
    };
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}
.min-h {
  max-height: 81vh;
  overflow-y: auto;
}
.w-75 {
  width: 75%;
}
</style>
